import React from "react"

import {
  Testimonial3ColumnWrapperElement,
  TestimonialWrapperElement,
  TestimonialImageElement,
  TestimonialNameElement,
  TestimonialMessageElement,
  TestimonialTitleElement,
  TestimonialImageWrapperElement,
} from "../elements/TestimonialElements"

import { StarIcon } from "@heroicons/react/solid"

export const Testimonial3ColumnWrapper = (props) => {
  return (
    <Testimonial3ColumnWrapperElement {...props}>{props.children}</Testimonial3ColumnWrapperElement>
  )
}

export const TestimonialWrapper = (props) => {
  return (
    <div className="flex flex-col justify-start my-0 mx-7 md:w-2/5 lg:w-1/4">{props.children}</div>
  )
}

export const TestimonialImage = (props) => {
  return (
    <TestimonialImageWrapperElement>
      {/* <TestimonialImageElement fixed={props.src} {...props}> */}
      <TestimonialImageElement image={props.src} {...props}>
        {props.children}
      </TestimonialImageElement>
    </TestimonialImageWrapperElement>
  )
}

export const TestimonialName = (props) => {
  return (
    <div className="text-center mt-0 mb-2 py-2.5 text-dark1 font-serif text-4xl leading-8 font-semibold ">
      {props.children}
    </div>
  )
}

export const TestimonialTitle = (props) => {
  return (
    <div className="text-center mt-0 mx-0 py-2 font-ptsans font-normal text-3xl text-primaryBlueDarker">
      {props.children}
      <div className="flex flex-row justify-center">
        <StarIcon className="text-yellow-300 w-7 h-7" />
        <StarIcon className="text-yellow-300 w-7 h-7" />
        <StarIcon className="text-yellow-300 w-7 h-7" />
        <StarIcon className="text-yellow-300 w-7 h-7" />
        <StarIcon className="text-yellow-300 w-7 h-7" />
      </div>
    </div>
  )
}

export const TestimonialMessage = (props) => {
  return <p className="text-center font-body text-dark2 text-sm">{props.children}</p>
}
