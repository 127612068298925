import React from "react"

import {
  ButtonWrapper,
  ButtonPrimaryInternalElement,
  ButtonPrimaryExternalElement,
  ButtonSecondaryInternalElement,
  ButtonSecondaryExternalElement,
  FormButtonSecondaryEnabledElement,
  FormButtonSecondaryDisabledElement,
  RightChevron,
} from "../elements/ButtonElements"

export const ButtonPrimary = (props) => {
  const internal = /^\/(?!\/)/.test(props.to)

  if (internal) {
    return (
      <ButtonWrapper {...props}>
        <ButtonPrimaryInternalElement {...props}>
          {props.children}
          &nbsp;
          <RightChevron />
        </ButtonPrimaryInternalElement>
      </ButtonWrapper>
    )
  }

  return (
    <ButtonWrapper {...props}>
      <ButtonPrimaryExternalElement href={props.to} {...props}>
        {props.children}
        &nbsp;
        <RightChevron />
      </ButtonPrimaryExternalElement>
    </ButtonWrapper>
  )
}

export const ButtonSecondary = (props) => {
  const internal = /^\/(?!\/)/.test(props.to)
  if (internal) {
    return (
      <ButtonWrapper {...props}>
        <ButtonSecondaryInternalElement {...props}>
          {props.children}
          &nbsp;
          <RightChevron />
        </ButtonSecondaryInternalElement>
      </ButtonWrapper>
    )
  }
  return (
    <ButtonWrapper {...props}>
      <ButtonSecondaryExternalElement href={props.to} {...props}>
        {props.children}
        &nbsp;
        <RightChevron />
      </ButtonSecondaryExternalElement>
    </ButtonWrapper>
  )
}

export const FormButtonSecondary = (props) => {
  if (props.enabled) {
    return (
      <ButtonWrapper {...props}>
        <FormButtonSecondaryEnabledElement {...props}>
          {props.children}
          {/* &nbsp;
        <RightChevron /> */}
        </FormButtonSecondaryEnabledElement>
      </ButtonWrapper>
    )
  } else {
    return (
      <ButtonWrapper {...props}>
        <FormButtonSecondaryDisabledElement disabled {...props}>
          {props.children}
          {/* &nbsp;
    <RightChevron /> */}
        </FormButtonSecondaryDisabledElement>
      </ButtonWrapper>
    )
  }
}
