import styled from "styled-components"
import { Link } from "gatsby"
// import { IconContext } from "react-icons"
import { FaChevronCircleRight } from "react-icons/fa"

export const ButtonWrapper = styled.div`
  display: flex;
  ${'' /* align-items: center;
  justify-content: center; */}
  flex-direction: row;

  @media screen and (min-width: 1024px) {
    ${'' /* align-items: left;
    justify-content:flex-start; */}
  }
  ${"" /* padding: 20px 22px; */}
`

export const ButtonPrimaryExternalElement = styled.a`
  display: flex;
  align-items: center;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.primaryBlueDarker};
  margin-top: 15px;
  padding: 20px 22px;
  color: ${(props) => props.theme.colors.white};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.primaryBlueDarker};
  ${"" /* font-size: 1.7rem; */}
  font-family: ${(props) => props.theme.fonts.button};
  font-size: ${(props) => props.theme.spacings.small};
  box-shadow: 3px 3px 15px grey;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primaryBlueDarker};
    ${"" /* padding: 11px 23px 11px 23px; */}
  }

  @media screen and (min-width: 1020px) {
    font-size: ${(props) => props.theme.spacings.medium};
  }
`

export const ButtonPrimaryInternalElement = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.primaryBlueDarker};
  margin-top: 15px;
  padding: 20px 22px;
  color: ${(props) => props.theme.colors.white};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.primaryBlueDarker};
  ${"" /* font-size: 1.7rem; */}
  font-family: ${(props) => props.theme.fonts.button};
  font-size: ${(props) => props.theme.spacings.small};
  box-shadow: 3px 3px 15px grey;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primaryBlueDarker};
    ${"" /* padding: 11px 23px 11px 23px; */}
  }

  @media screen and (min-width: 1020px) {
    font-size: ${(props) => props.theme.spacings.medium};
  }
`
export const FormButtonSecondaryEnabledElement = styled.button`
  align-items: center;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.primaryOrangeNormal};
  margin-top: 15px;
  padding: 20px 22px;
  color: ${(props) => props.theme.colors.white};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.primaryOrangeNormal};
  ${"" /* font-size: 1.7rem; */}
  font-family: ${(props) => props.theme.fonts.button};
  font-size: ${(props) => props.theme.spacings.small};
  box-shadow: 3px 3px 15px grey;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => props.theme.colors.primaryOrangeDarker};
    border: 1px solid ${(props) => props.theme.colors.primaryOrangeDarker};
    color: ${(props) => props.theme.colors.white};
    ${"" /* padding: 11px 23px 11px 23px; */}
  }
  &:focus {
    cursor: wait;
  }

  @media screen and (min-width: 1020px) {
    font-size: ${(props) => props.theme.spacings.small};
  }
`

export const FormButtonSecondaryDisabledElement = styled.button`
  align-items: center;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.dark3};
  margin-top: 15px;
  padding: 20px 22px;
  color: ${(props) => props.theme.colors.dark1};
  outline: none;
  border: none;
  cursor: not-allowed;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.dark3};
  ${"" /* font-size: 1.7rem; */}
  font-family: ${(props) => props.theme.fonts.button};
  font-size: ${(props) => props.theme.spacings.small};
  box-shadow: 3px 3px 15px grey;
  ${
    "" /* &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => props.theme.colors.dark2};
    border: 1px solid ${(props) => props.theme.colors.dark2};
    color: ${(props) => props.theme.colors.white};
    ${"" /* padding: 11px 23px 11px 23px; */
  }
  } */}

  @media screen and (min-width: 1020px) {
    font-size: ${(props) => props.theme.spacings.small};
  }
`

export const ButtonSecondaryExternalElement = styled.a`
  display: flex;
  align-items: center;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.primaryOrangeNormal};
  margin-top: 15px;
  padding: 20px 22px;
  color: ${(props) => props.theme.colors.white};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.primaryOrangeNormal};
  ${"" /* font-size: 1.7rem; */}
  font-family: ${(props) => props.theme.fonts.button};
  font-size: ${(props) => props.theme.spacings.small};
  box-shadow: 3px 3px 15px grey;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => props.theme.colors.primaryOrangeDarker};
    border: 1px solid ${(props) => props.theme.colors.primaryOrangeDarker};
    color: ${(props) => props.theme.colors.white};
    ${"" /* padding: 11px 23px 11px 23px; */}
  }

  @media screen and (min-width: 1020px) {
    font-size: ${(props) => props.theme.spacings.small};
    align-items: left;
  }
`

export const ButtonSecondaryInternalElement = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.primaryOrangeNormal};
  margin-top: 15px;
  padding: 20px 22px;
  color: ${(props) => props.theme.colors.white};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.primaryOrangeNormal};
  ${"" /* font-size: 1.7rem; */}
  font-family: ${(props) => props.theme.fonts.button};
  font-size: ${(props) => props.theme.spacings.small};
  box-shadow: 3px 3px 15px grey;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => props.theme.colors.primaryOrangeDarker};
    border: 1px solid ${(props) => props.theme.colors.primaryOrangeDarker};
    color: ${(props) => props.theme.colors.white};
    ${"" /* padding: 11px 23px 11px 23px; */}
  }

  @media screen and (min-width: 1020px) {
    font-size: ${(props) => props.theme.spacings.small};
    align-items: left;
  }
`

export const RightChevron = styled(FaChevronCircleRight)`
  display: none;

  @media screen and (min-width: 1020px) {
    display: block;
    color: ${(props) => props.theme.colors.white};
    margin-left: 5px;

    ${ButtonPrimaryInternalElement}:hover & {
      transition: all 0.2s ease-in-out;
      color: ${(props) => props.theme.colors.primaryBlueNormal};
    }

    ${ButtonPrimaryExternalElement}:hover & {
      transition: all 0.2s ease-in-out;
      color: ${(props) => props.theme.colors.primaryBlueNormal};
    }
  }
`
