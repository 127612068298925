import styled from "styled-components"
import Img from "gatsby-image"
import { getImage, getSrc, getSrcSet , GatsbyImage} from "gatsby-plugin-image"

export const Testimonial3ColumnWrapperElement = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1020px) {
    flex-direction: row;
  }
`

export const TestimonialWrapperElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
`

export const TestimonialImageWrapperElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

// export const TestimonialImageElement = styled.img`
//   border-radius: 50%;
//   box-shadow: 3px 3px 15px grey;
//   margin: 30px;
// `

// export const TestimonialImageElement = styled(Img)`
//   border-radius: 50%;
//   box-shadow: 3px 3px 15px grey;
//   margin: 30px;
// `

export const TestimonialImageElement = styled(GatsbyImage)`
  border-radius: 50%;
  box-shadow: 3px 3px 15px grey;
  margin: 30px;
`

export const TestimonialNameElement = styled.div`
  text-align: center;

  margin-top: 0px;
  margin-bottom: "auto";

  padding-top: 10px;
  padding-bottom: 10px;

  font-family: ${(props) => props.theme.fonts.header3};
  font-weight: ${(props) => props.theme.fonts.weight600};
  font-size: ${(props) => props.theme.spacings.xMedium};
  line-height: ${(props) => props.theme.spacings.xMedium};

  color: ${(props) => props.theme.colors.dark1};
`
export const TestimonialTitleElement = styled.div`
  text-align: center;

  margin-top: 0;
  margin-left: 0;
  margin-right: 0;

  padding-top: 10px;
  padding-bottom: 10px;

  font-family: ${(props) => props.theme.fonts.header4};
  font-weight: ${(props) => props.theme.fonts.weight00};
  font-size: ${(props) => props.theme.spacings.medium};

  color: ${(props) => props.theme.colors.primaryBlueDarker};
`

export const TestimonialMessageElement = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.spacings.xSmall};
  color: ${(props) => props.theme.colors.dark2};
`
